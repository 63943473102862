export default {
  ORDER: {
    CELLGKO_CLONE: '/order/engineered-cells/crispr-knockout-cell-clone',
    CELLGKO_POOL: '/order/engineered-cells/crispr-knockout-cell-pool',
    CK_SYNTHETIC_SGRNA: '/order/crispr-kits/synthetic-sgrna'
  },
  URL_TO_CONTENT_TYPE: {
    '/order/engineered-cells/crispr-knockout-cell-clone':
      'order_knockout_cell_clone',
    '/order/engineered-cells/crispr-knockout-cell-pool': 'order_cell_gko',
    '/order/crispr-kits/synthetic-sgrna': 'ck_crisprevolution',
    '/order/crispr-kits/gene-knockout-kit': 'order_ck_multiplex'
  },
  REFERENCES_FOR_URL: {
    '/order/crispr-kits/synthetic-sgrna': [
      'order_benefits',
      'order_widgets.cr_order_widgets.multiplex_order_widget',
      'order_widgets.cr_order_widgets.rna_order_widget'
    ],
    '/order/crispr-kits/gene-knockout-kit': [
      'order_benefits',
      'order_widgets.cr_order_widgets.multiplex_order_widget',
      'order_widgets.cr_order_widgets.rna_order_widget'
    ],
    DEFAULT: []
  }
};
