export const addOnConfigJsonBySlug = {
  cas9_2nls_nuclease_300pmol: {
    capacity: 1,
    name: 'SpCas9 2NLS Nuclease (300 pmol)',
    product_pk: 17,
    format: 'stan',
    static_scale: {
      cas9_2nls_nuclease_300pmol: [],
      te_buffer: [],
      cas9_2nls_nuclease_1000pmol: [],
      control_neg_sgrna_mod_2: [],
      controls_kit_human: [],
      annealing_buffer: [],
      control_pos_sgrna_mod_human_cdc42bpb: [],
      control_pos_sgrna_mod_human_rela: [],
      tok_multiguide: [],
      rnaase_free_water: [],
      control_neg_sgrna_mod_1: [],
      control_pos_sgrna_mod_mouse_rosa26: []
    },
    sequence: 'Add-On Product',
    product_length: {
      cas9_2nls_nuclease_300pmol: {
        min: 0,
        min_warn: 'This should not happen.',
        max: 100,
        max_warn: 'This should not happen.'
      }
    },
    sequence_raw_encoding: '',
    product_slug: 'cas9_2nls_nuclease_300pmol',
    product_type: {
      id: 'cas9_2nls_nuclease_300pmol',
      name: 'SpCas9 2NLS Nuclease (300 pmol)'
    },
    quantity: 1,
    advanced: 'False'
  },
  cas9_2nls_nuclease_1000pmol: {
    capacity: 1,
    name: 'SpCas9 2NLS Nuclease (1000 pmol)',
    product_pk: 28,
    format: 'stan',
    static_scale: {
      cas9_2nls_nuclease_300pmol: [],
      te_buffer: [],
      cas9_2nls_nuclease_1000pmol: [],
      control_neg_sgrna_mod_2: [],
      controls_kit_human: [],
      annealing_buffer: [],
      control_pos_sgrna_mod_human_cdc42bpb: [],
      control_pos_sgrna_mod_human_rela: [],
      tok_multiguide: [],
      rnaase_free_water: [],
      control_neg_sgrna_mod_1: [],
      control_pos_sgrna_mod_mouse_rosa26: []
    },
    sequence: 'Add-On Product',
    product_length: {
      cas9_2nls_nuclease_1000pmol: {
        min: 0,
        min_warn: 'This should not happen.',
        max: 100,
        max_warn: 'This should not happen.'
      }
    },
    sequence_raw_encoding: '',
    product_slug: 'cas9_2nls_nuclease_1000pmol',
    product_type: {
      id: 'cas9_2nls_nuclease_1000pmol',
      name: 'SpCas9 2NLS Nuclease (1000 pmol)'
    },
    quantity: 1,
    advanced: 'False'
  },
  tok_multiguide: {
    capacity: 1,
    name: 'tok multiguide',
    product_pk: 49,
    format: 'stan',
    static_scale: {
      cas9_2nls_nuclease_300pmol: [],
      te_buffer: [],
      cas9_2nls_nuclease_1000pmol: [],
      control_neg_sgrna_mod_2: [],
      controls_kit_human: [],
      annealing_buffer: [],
      control_pos_sgrna_mod_human_cdc42bpb: [],
      control_pos_sgrna_mod_human_rela: [],
      tok_multiguide: [],
      rnaase_free_water: [],
      control_neg_sgrna_mod_1: [],
      control_pos_sgrna_mod_mouse_rosa26: []
    },
    sequence: 'Add-On Product',
    product_length: {
      tok_multiguide: {
        min: 0,
        max: 100,
        max_warn: 'This should not happen.',
        min_warn: 'This should not happen.'
      }
    },
    sequence_raw_encoding: '',
    product_slug: 'tok_multiguide',
    product_type: {
      id: 'tok_multiguide',
      name: 'Transfection Optimization Kit (Multi-guide)'
    },
    quantity: 1,
    advanced: 'False'
  },
  control_pos_sgrna_mod_mouse_rosa26: {
    capacity: 1,
    name: 'rosa26',
    product_pk: 300,
    format: 'stan',
    static_scale: {
      cas9_2nls_nuclease_300pmol: [],
      te_buffer: [],
      cas9_2nls_nuclease_1000pmol: [],
      control_neg_sgrna_mod_2: [],
      controls_kit_human: [],
      annealing_buffer: [],
      control_pos_sgrna_mod_human_cdc42bpb: [],
      control_pos_sgrna_mod_human_rela: [],
      tok_multiguide: [],
      rnaase_free_water: [],
      control_neg_sgrna_mod_1: [],
      control_pos_sgrna_mod_mouse_rosa26: []
    },
    sequence: 'Add-On Product',
    product_length: {
      control_pos_sgrna_mod_mouse_rosa26: {
        min: 0,
        max: 100,
        max_warn: 'This should not happen.',
        min_warn: 'This should not happen.'
      }
    },
    sequence_raw_encoding: '',
    product_slug: 'control_pos_sgrna_mod_mouse_rosa26',
    product_type: {
      id: 'control_pos_sgrna_mod_mouse_rosa26',
      name: 'Positive Control, Mouse Rosa26, mod-sgRNA, 1nmol, Tube'
    },
    quantity: 1,
    advanced: 'False'
  }
};
