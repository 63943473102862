import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_AddToBuckCart = productConfig =>
  fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: [
      {
        product_format: 'tube',
        ...productConfig
      }
    ]
    // could add product_format to the initial State, but currently there is nothing in the widget
    // to explicitly require this
  });
