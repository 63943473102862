import actionComposer from 'utilities/actionComposer';

import { queryContentStackById } from 'utilities/contentStack';

import { api_AddMultiplexToBuckCart } from 'features/multiplexorder/api';

import { api_getMultiplexGenes, api_getSkuPricing } from './api';

export const ADD_MULTIPLEX_SGRNA_TO_CART = actionComposer(
  'ADD_MULTIPLEX_SGRNA_TO_CART'
);
export const addMultiplexSgrnaToCart = (config, addOn) => ({
  type: ADD_MULTIPLEX_SGRNA_TO_CART.type,
  payload: api_AddMultiplexToBuckCart(config, addOn)
});

export const GET_MULTIPLEX_GENES = actionComposer('GET_MULTIPLEX_GENES');
export const getMultiplexGenes = () => ({
  type: GET_MULTIPLEX_GENES.type,
  payload: api_getMultiplexGenes('human')
});

export const GET_MULTIPLEX_MOUSE_GENES = actionComposer(
  'GET_MULTIPLEX_MOUSE_GENES'
);
export const getMultiplexMouseGenes = () => ({
  type: GET_MULTIPLEX_MOUSE_GENES.type,
  payload: api_getMultiplexGenes('mouse')
});

export const GET_MULTIPLEX_ORDER_STACK_BY_ID = actionComposer(
  'GET_MULTIPLEX_ORDER_STACK_BY_ID'
);
export const getMultplexOrderStackById = id => ({
  type: GET_MULTIPLEX_ORDER_STACK_BY_ID.type,
  payload: queryContentStackById('multiplex_widget', id)
});

export const GET_SKU_PRICING = actionComposer('GET_SKU_PRICING');
export const getSkuPricing = skuList => ({
  type: GET_SKU_PRICING.type,
  payload: api_getSkuPricing(skuList)
});

export const RESET_REDIRECT_TO_CART = 'RESET_REDIRECT_TO_CART';
export const resetRedirectToCart = () => ({
  type: RESET_REDIRECT_TO_CART
});

export const SET_MULTIPLEX_GENOME = 'SET_MULTIPLEX_GENOME';
export const setMultiplexGenome = genome => ({
  type: SET_MULTIPLEX_GENOME,
  genome: genome
});

export const SET_MULTIPLEX_GENE = 'SET_MULTIPLEX_GENE';
export const setMultiplexGene = gene => ({
  type: SET_MULTIPLEX_GENE,
  gene: gene
});

export const SET_ADD_ON = 'SET_ADD_ON';
export const setAddOn = selected => ({
  type: SET_ADD_ON,
  value: selected
});

export const SET_YIELD = 'SET_YIELD';
export const setYield = selected => ({
  type: SET_YIELD,
  value: selected
});
