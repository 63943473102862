import {
  GET_ODL_STACK,
  GET_ODL_GENES,
  SET_PROJECT_DETAILS,
  SET_PROJECT_NAME,
  SET_PROJECT_QUANTITY,
  SET_PROJECT_REPLICATES,
  SET_ACTIVE_PLATE,
  SET_ADD_DIALOG_METHOD,
  RESET_PROJECT,
  ADD_EMPTY_PLATE,
  DELETE_PLATE,
  SET_PLATE_NEW,
  ADD_GENES_TO_PLATE,
  UPDATE_PLATE_WELLS,
  UPDATE_WELL,
  ADD_PROJECT_TO_CART,
  CLEAR_CART_ERRORS,
  GET_ODL_PRICE
} from './actions';

import { version } from './constants';

export const getInitialState = () => ({
  project: {
    version: version,
    name: '',
    product_slug: 'on_demand_libraries',
    genome: null,
    plateType: null,
    quantityPerWell: null,
    replicates: 0,
    listPricePerGene: 0,
    plates: [],
    pricePerGene: 0,
    activePlate: 0,
    addDialogMethod: null
  },
  stack: {},
  stackLoading: true,
  validGenes: {},
  validGenesLoading: false,
  cartError: false,
  cartErrorMessage: null,
  cartLoading: false,
  redirectToCart: false,
  priceLoading: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_ODL_STACK.PENDING:
      return {
        ...state,
        stackLoading: true
      };
    case GET_ODL_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload[0],
        stackLoading: false
      };
    case GET_ODL_STACK.REJECTED:
      return {
        ...state,
        stackLoading: false
      };
    case GET_ODL_GENES.PENDING:
      return {
        ...state,
        validGenesLoading: true
      };
    case GET_ODL_GENES.FULFILLED:
      return {
        ...state,
        validGenes: action.payload,
        validGenesLoading: false
      };
    case GET_ODL_GENES.REJECTED:
      return {
        ...state,
        validGenesLoading: false
      };
    case ADD_PROJECT_TO_CART.PENDING:
      return {
        ...state,
        cartLoading: true,
        cartError: false
      };
    case ADD_PROJECT_TO_CART.FULFILLED:
      return {
        ...state,
        cartLoading: false,
        cartError: false,
        redirectToCart: true
      };
    case ADD_PROJECT_TO_CART.REJECTED:
      return {
        ...state,
        cartLoading: false,
        cartError: true,
        cartErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload
      };
    case CLEAR_CART_ERRORS:
      return {
        ...state,
        cartError: false,
        cartErrorMessage: null,
        redirectToCart: false
      };
    case SET_PROJECT_DETAILS:
      return {
        ...state,
        project: { ...state.project, ...action.newDetails }
      };
    case SET_PROJECT_NAME:
      return {
        ...state,
        project: { ...state.project, name: action.newName }
      };
    case SET_PROJECT_QUANTITY:
      return {
        ...state,
        project: { ...state.project, quantityPerWell: action.newQuantity }
      };
    case SET_PROJECT_REPLICATES:
      return {
        ...state,
        project: { ...state.project, replicates: action.newReplicatesCount }
      };

    case SET_ACTIVE_PLATE:
      return {
        ...state,
        project: { ...state.project, activePlate: action.plateIdx }
      };
    case SET_ADD_DIALOG_METHOD:
      return {
        ...state,
        project: { ...state.project, addDialogMethod: action.value }
      };
    case RESET_PROJECT:
      return getInitialState();
    case ADD_EMPTY_PLATE:
      return {
        ...state,
        project: {
          ...state.project,
          plates: [...state.project.plates, action.plate]
        }
      };
    case DELETE_PLATE:
      return {
        ...state,
        project: {
          ...state.project,
          plates: [
            ...state.project.plates.slice(0, action.plateIdx),
            ...state.project.plates.slice(
              action.plateIdx + 1,
              state.project.plates.length
            )
          ]
        }
      };
    case SET_PLATE_NEW:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              new: action.value
            }
          })
        }
      };
    case ADD_GENES_TO_PLATE.PENDING:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.meta.plateIdx]: {
              ...state.project.plates[action.meta.plateIdx],
              loading: true
            }
          })
        }
      };

    case ADD_GENES_TO_PLATE.FULFILLED:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.meta.plateIdx]: {
              ...state.project.plates[action.meta.plateIdx],
              new: false,
              loading: false,
              wells: Object.assign(
                [...state.project.plates[action.meta.plateIdx].wells],
                {
                  ...action.payload
                }
              )
            }
          })
        }
      };
    case UPDATE_PLATE_WELLS:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              wells: action.newWells
            }
          })
        }
      };

    case UPDATE_WELL:
      return {
        ...state,
        project: {
          ...state.project,
          plates: Object.assign([...state.project.plates], {
            [action.plateIdx]: {
              ...state.project.plates[action.plateIdx],
              wells: Object.assign(
                [...state.project.plates[action.plateIdx].wells],
                {
                  [action.wellIdx]: {
                    ...state.project.plates[action.plateIdx].wells[
                      action.wellIdx
                    ],
                    ...action.newWell
                  }
                }
              )
            }
          })
        }
      };

    case GET_ODL_PRICE.PENDING:
      return {
        ...state,
        priceLoading: true
      };
    case GET_ODL_PRICE.FULFILLED:
      return {
        ...state,
        priceLoading: false,
        project: {
          ...state.project,
          listPricePerGene: action.payload.list_price_per_gene,
          pricePerGene: action.payload.price_per_gene
        }
      };
    case GET_ODL_PRICE.REJECTED:
      return {
        ...state,
        priceLoading: false
      };

    default:
      return state;
  }
};
