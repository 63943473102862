import _ from 'lodash';

export default data => {
  const email = _.get(data, 'email', '');
  const id = _.get(data, 'id', null);
  const hasVerifiedEmail = _.get(data, 'has_verified_email', false);
  const isAuthenticated = !_.isEmpty(id) || _.isNumber(id);
  return {
    email: email,
    company_name: _.get(data, 'company_name', ''),
    emailPrefix: email ? _.split(email, '@')[0] : '',
    first_name: _.get(data, 'first_name', ''),
    giftCardLimit: _.get(data, 'gift_card_limit', 2),
    id: id,
    hasVerifiedEmail: hasVerifiedEmail,
    isAuthenticated: isAuthenticated,
    isInEduBucket: _.get(data, 'price_strategy.name', '') === 'edu-40',
    isSynthego: _.includes(email, '@synthego.com'),
    last_name: _.get(data, 'last_name', ''),
    numGiftCards: _.get(data, 'num_gift_cards', 0),
    groups: _.get(data, 'groups', []),
    tshirt_size: _.get(data, 'tshirt_size', ''),
    loginStatus: isAuthenticated
      ? hasVerifiedEmail
        ? 'logged_in'
        : 'email_not_verified'
      : 'logged_out'
  };
};
