import {
  CLEAR_CART,
  EDIT_LINE_ITEM,
  GET_CART_STACK,
  GET_BUCK_USER_CART,
  SET_ACTIVE_BUCK_USER_CART,
  REMOVE_ITEM,
  UPDATE_ITEM_QUANTITY
} from './actions';

import { UPDATE_SGRNA_KIT_IN_CART } from 'features/rnaorder/actions';

export const getInitialState = () => ({
  cartShouldUpdate: false,
  cartItemCount: 0,
  cartIsEmpty: true,
  loading: false,
  error: false,
  edit_url_for_product: '',
  stack: {
    bottom_text: '',
    cart_total_text: '',
    checkout_button_text: '',
    coupon_help_text: '',
    design_tool_text: {
      thanks_text: '',
      guides_text: '',
      add_guides_text: '',
      icon: {
        url: ''
      }
    },
    edit_button_text: '',
    empty_cart_notification: '',
    home_button_text: '',
    quote_button_text: '',
    remove_button_text: '',
    table_headers: {
      product: '',
      quantity: '',
      unit_price: '',
      line_total: ''
    },
    title: '',
    related_references: []
  },
  cartDetail: {
    order_id: '',
    created: '',
    undiscounted_subtotal: '',
    groups: [
      {
        id: '',
        fedex_account_number: '',
        items: [],
        last_updated: '',
        order: '',
        shipping_amount_charged: '',
        shipping_method_name: null,
        status: '',
        tracking_number: ''
      }
    ]
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case CLEAR_CART.PENDING:
      return {
        ...state,
        loading: true
      };
    case CLEAR_CART.FULFILLED:
      return {
        ...state,
        cartShouldUpdate: true,
        loading: false
      };
    case CLEAR_CART.REJECTED:
      return {
        ...state,
        loading: false
      };
    case EDIT_LINE_ITEM.FULFILLED:
      return {
        ...state,
        edit_url_for_product: action.meta.url,
        loading: false
      };
    case GET_BUCK_USER_CART.FULFILLED:
      const cart = action.payload;
      const items = cart.groups[0].items;
      return {
        ...state,
        cartShouldUpdate: false,
        cartDetail: cart,
        cartItemCount: items.length,
        cartIsEmpty: items.length < 1,
        loading: false
      };
    case SET_ACTIVE_BUCK_USER_CART.PENDING:
      return {
        ...state,
        loading: true
      };
    case SET_ACTIVE_BUCK_USER_CART.FULFILLED:
      return {
        ...state,
        cartShouldUpdate: true,
        loading: false,
        error: false
      };
    case SET_ACTIVE_BUCK_USER_CART.REJECTED:
      return {
        ...state,
        cartShouldUpdate: true,
        loading: false,
        error: true
      };
    case GET_CART_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_CART_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload,
        error: false
      };
    case GET_CART_STACK.REJECTED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case REMOVE_ITEM.PENDING:
      return {
        ...state,
        loading: true
      };
    case REMOVE_ITEM.FULFILLED:
      return {
        ...state,
        cartShouldUpdate: true,
        loading: false,
        error: false
      };
    case REMOVE_ITEM.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_ITEM_QUANTITY.PENDING:
      return {
        ...state,
        loading: true
      };
    case UPDATE_ITEM_QUANTITY.FULFILLED:
      return {
        ...state,
        cartShouldUpdate: true,
        loading: false,
        error: false
      };
    case UPDATE_ITEM_QUANTITY.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_SGRNA_KIT_IN_CART.PENDING:
      return {
        ...state,
        edit_url_for_product: ''
      };
    default:
      return state;
  }
};
