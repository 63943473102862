import config from 'init/config';
import { fRequest } from 'utilities/api';
import { indexToWellLocation } from './utilities';
import _ from 'lodash';

export const api_AddToBuckCart = odlProject => {
  //Add positions to wells
  const odlProjectWithPositions = _.cloneDeep(odlProject);
  odlProjectWithPositions.plates = odlProjectWithPositions.plates.map(plate => {
    const wellsWithPositions = plate.wells.map((well, idx) => {
      if (well.value) {
        return {
          position: indexToWellLocation(idx, odlProject.plateType.wellRows),
          ...well
        };
      } else {
        return null;
      }
    });
    //Remove empty wells
    plate.wells = wellsWithPositions.filter(Boolean);
    return plate;
  });
  return fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: [
      {
        ...odlProjectWithPositions
      }
    ]
  });
};

export const api_GetODLGenes = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/odl/genes/?species=${species}`,
    {
      method: 'GET'
    }
  );
};

export const api_GetODLPrice = (quantityPerWell, replicates, totalNumGenes) => {
  return fRequest(
    `${config.buckRootUrl}/api/order/on-demand-libraries/pricing/?quantity_per_well=${quantityPerWell}&replicates=${replicates}&total_num_genes=${totalNumGenes}`,
    {
      method: 'GET'
    }
  );
};
