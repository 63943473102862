import _ from 'lodash';
import {
  ADD_SGRNA_KIT_TO_CART,
  GET_RNA_ORDER_STACK_BY_ID,
  UPDATE_CUSTOMER_LABEL,
  UPDATE_CUSTOMER_SEQUENCE,
  SET_DISPLAY_LABEL_ERROR,
  SET_IS_VALID_LABEL,
  SET_MODIFICATION,
  SET_YIELD,
  UPDATE_SGRNA_KIT_IN_CART
} from './actions';

import { EDIT_LINE_ITEM } from 'features/cart/actions';
import { GET_BUCK_PRICING } from 'features/buckaneer/priceOptionsUtils/actions';

import {
  filterPrices,
  getYieldAndModOptions
} from 'features/buckaneer/priceOptionsUtils/optionsUtils';
import { isValidLabel } from './validationUtils';

export const getInitialState = () => ({
  allBuckPricing: {},

  customer_label: '',
  customer_sequence: '',

  processedUserOptions: {},

  display_label_error: false,
  display_sequence_error: false,

  is_valid_label: false,
  is_valid_sequence: false,

  label_in_cart_message: '',
  loading: false,

  product_sku: '',

  render_as_update: false,

  selected_modification: 'stb_methyl_pp_thioate',
  selected_yield: '',

  redirect_to_cart: false,

  stack: {}
});

const sgRNAOptionsConfig = { product_slug: 'sgrna_kit' };

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case ADD_SGRNA_KIT_TO_CART.FULFILLED:
      return {
        ...state,
        loading: false,
        redirect_to_cart: true
      };
    case ADD_SGRNA_KIT_TO_CART.PENDING:
      return {
        ...state,
        loading: true
      };
    case ADD_SGRNA_KIT_TO_CART.REJECTED:
      return {
        ...state,
        loading: false,
        display_label_error: true,
        is_valid_label: false,
        label_in_cart_message: action.payload.error_message
      };
    case EDIT_LINE_ITEM.FULFILLED:
      const {
        guaranteed_yield,
        modification,
        customer_label,
        sequence,
        product_sku
      } = action.payload;
      return {
        ...state,
        customer_label: customer_label,
        customer_sequence: sequence,
        redirect_to_cart: false,
        render_as_update: true,
        product_sku,
        selected_modification: modification,
        selected_yield: guaranteed_yield
      };
    case GET_BUCK_PRICING.FULFILLED:
      const processedUserOptions = getYieldAndModOptions(
        filterPrices(action.payload, sgRNAOptionsConfig)
      );
      const { minLength, maxLength } = processedUserOptions;
      const is_valid_sequence = _.inRange(
        state.customer_sequence.length,
        minLength,
        maxLength
      );
      const is_valid_label = isValidLabel(state.customer_label.length);
      return {
        ...state,
        allBuckPricing: action.payload,
        is_valid_label,
        is_valid_sequence,
        processedUserOptions
      };
    case GET_BUCK_PRICING.PENDING:
      return {
        ...state
      };
    case GET_BUCK_PRICING.REJECTED:
      return {
        ...state,
        allBuckPricing: {}
      };
    case GET_RNA_ORDER_STACK_BY_ID.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_RNA_ORDER_STACK_BY_ID.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_RNA_ORDER_STACK_BY_ID.REJECTED:
      return {
        ...state,
        loading: false
      };
    case SET_DISPLAY_LABEL_ERROR:
      return {
        ...state,
        display_label_error: action.value,
        is_valid_label: !action.value
      };
    case SET_IS_VALID_LABEL:
      return {
        ...state,
        is_valid_label: action.value
      };
    case SET_MODIFICATION:
      return {
        ...state,
        selected_modification: action.value.value
      };
    case SET_YIELD:
      return {
        ...state,
        selected_yield: action.value.value
      };
    case UPDATE_CUSTOMER_LABEL:
      const validLength = isValidLabel(action.text.length);
      return {
        ...state,
        customer_label: action.text,
        label_in_cart_message: '',
        display_label_error: !validLength,
        is_valid_label: validLength
      };
    case UPDATE_CUSTOMER_SEQUENCE:
      return {
        ...state,
        customer_sequence: action.text,
        display_sequence_error: action.value,
        is_valid_sequence: !action.value
      };
    case UPDATE_SGRNA_KIT_IN_CART.FULFILLED:
      return {
        ...state,
        loading: false,
        redirect_to_cart: true
      };
    case UPDATE_SGRNA_KIT_IN_CART.PENDING:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SGRNA_KIT_IN_CART.REJECTED:
      return {
        ...state,
        loading: false,
        display_label_error: true,
        is_valid_label: false,
        label_in_cart_message: action.payload.error_message
      };
    default:
      return state;
  }
};
