import { GET_OFFER_STACK, SET_CLAIM_FORM_VISIBILITY } from './actions';

export const getInitialState = () => ({
  stack: {
    claim_content: {
      hubspot_form: {
        form_label: '',
        form_id: ''
      }
    },
    redeem_content: [
      {
        cr_order_widgets: {
          widgets: {
            rna_order_widget: [],
            multiplex_order_widget: []
          },
          order_benefits: [],
          label: ''
        }
      },
      {
        coupon_code: {
          prefix: '',
          code: '',
          suffix: ''
        }
      }
    ],
    ineligible_content: {
      heading: '',
      text: ''
    },
    legal: {
      button_label: '',
      text: ''
    },
    more_info: [],
    quote: [],
    related_references: {
      resource_references: [],
      blog_references: []
    }
  },
  showClaimForm: false,
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_OFFER_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_OFFER_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_OFFER_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };

    case SET_CLAIM_FORM_VISIBILITY:
      return {
        ...state,
        showClaimForm: action.payload
      };
    default:
      return state;
  }
};
