// Genome options, plate options and control labels get stored in the users localstorage once they start a project.
// If you change any of their properties increase the version, users will be prompted
// to download their project and start over so they don't have outdated information stored.
export const version = 14;

export const orderRequirements = {
  maxProjectWells: 500,
  minPlateWells: 20,
  maxProjectGenePmol: 10000, //Maximum number of pmol of a single gene allowed in a project
  minProjectCopies: 1,
  maxProjectCopies: 5
};

export const genomeOptions = [
  {
    label: 'Homo sapiens',
    caption: 'Gencode Release 38 (GRCh38.p13) Primary',
    commonName: 'human',
    slug: 'homo_sapiens'
  },
  {
    label: 'Mus musculus',
    caption: 'Gencode Release M27 (GRCm39)',
    commonName: 'mouse',
    slug: 'mus_musculus'
  }
];

export const plateOptions = [
  {
    label: '96-well',
    caption: 'Polypropylene',
    slug: '1mL deep well',
    wellCount: 96,
    wellRows: 8,
    wellColumns: 12,
    quantities: [1500] //Well sizes in pmol this plate is available to order in
  },
  {
    label: '384-well',
    caption: 'Polypropylene',
    slug: '384-well V-bottom plate',
    wellCount: 384,
    wellRows: 16,
    wellColumns: 24,
    quantities: [150, 250]
  },
  {
    label: '384-well',
    caption: 'Echo-Validated',
    slug: '384-well Echo plate',
    wellCount: 384,
    wellRows: 16,
    wellColumns: 24,
    quantities: [150]
  }
];

export const controlOptions = [
  {
    label: 'RELA Positive',
    caption: 'Positive Control sgRNA (mod), Human RELA',
    slug: 'rela_positive',
    singleguide: true,
    genomes: ['homo_sapiens'] //The genomes this control is available to use on
  },
  {
    label: 'CDCR2BPB',
    caption: 'Positive Control sgRNA (mod), Human CDC42BPB',
    slug: 'cdcr2bpb',
    singleguide: true,
    genomes: ['homo_sapiens']
  },
  {
    label: 'NTC1',
    caption: 'Negative Control sgRNA (mod) #1',
    slug: 'ntc1',
    singleguide: true,
    genomes: ['homo_sapiens', 'mus_musculus']
  },
  {
    label: 'NTC2',
    caption: 'Negative Control sgRNA (mod) #2',
    slug: 'ntc2',
    singleguide: true,
    genomes: ['homo_sapiens', 'mus_musculus']
  },
  {
    label: 'Rosa26',
    caption: 'Positive Control, Mouse Rosa26, mod-sgRNA',
    slug: 'rosa26',
    singleguide: true,
    genomes: ['mus_musculus']
  },
  {
    label: 'TRAC',
    caption: 'Positive Control sgRNA (mod), Human TRAC',
    slug: 'trac',
    singleguide: false,
    genomes: ['homo_sapiens']
  }
];

export const warningColor = '#ffca28';

export const ERRORS = {
  GENE_NOT_FOUND: 'GENE_NOT_FOUND',
  ZERO_GUIDES: 'ZERO_GUIDES'
};

export const WARNINGS = {
  FEWER_THAN_THREE_GUIDES: 'FEWER_THAN_THREE_GUIDES'
};
