import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';
import URLS from 'constants/URLS';

export const ORDER_GET_ORDER_STACK = actionComposer('ORDER_GET_ORDER_STACK');
export const getOrderStack = url => {
  const contentType = URLS.URL_TO_CONTENT_TYPE[url.replace(/\/+$/, '')];
  const references =
    URLS.REFERENCES_FOR_URL[url.replace(/\/+$/, '')] ||
    URLS.REFERENCES_FOR_URL.DEFAULT;

  return {
    type: ORDER_GET_ORDER_STACK.type,
    payload: queryContentStackByUrl(contentType, url, {
      includedReferences: references
    })
  };
};
