import actionComposer from 'utilities/actionComposer';
import { api_getOrderDetails, api_recalculateOrderQuote } from './api';

export const GET_ORDER_DETAILS = actionComposer('GET_ORDER_DETAILS');
export const getOrderDetails = orderToken => ({
  type: GET_ORDER_DETAILS.type,
  payload: api_getOrderDetails(orderToken)
});

export const RECALCULATE_ORDER_QUOTE = actionComposer(
  'RECALCULATE_ORDER_QUOTE'
);
export const recalculateOrderQuote = orderToken => ({
  type: RECALCULATE_ORDER_QUOTE.type,
  payload: api_recalculateOrderQuote(orderToken)
});
