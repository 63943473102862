import config from 'init/config';
import { fRequest } from 'utilities/api';
import { addOnConfigJsonBySlug } from 'features/buckaneer/addOnProducts';

function getAddOnConfig(slug) {
  return slug !== 'none' ? addOnConfigJsonBySlug[slug] : null;
}

function buildOrderData(productConfig, addOnSlug) {
  var data = [
    {
      // product_format: 'tube',
      ...productConfig
    },
    getAddOnConfig(addOnSlug)
  ];
  //  if addOnSlug is none remove it.
  if (data[data.length - 1] === null) {
    data.pop();
  }
  return data;
}

export const api_getMultiplexGenes = genome =>
  fRequest(
    `${config.buckRootUrl}/api/products/multiplex/genes?genome=${genome}`,
    {
      method: 'GET'
    }
  );

export const api_AddMultiplexToBuckCart = (productConfig, addOnSlug) =>
  fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: buildOrderData(productConfig, addOnSlug)
  });

export const api_getSkuPricing = skuList =>
  fRequest(`${config.buckRootUrl}/products/sku-lookup/`, {
    method: 'POST',
    data: skuList
  });
