import actionComposer from 'utilities/actionComposer';
import { queryContentStackById } from 'utilities/contentStack';
import { api_AddToBuckCart } from './api';

export const ADD_SGRNA_KIT_TO_CART = actionComposer('ADD_SGRNA_KIT_TO_CART');
export const addSgRNAKitToCart = config => ({
  type: ADD_SGRNA_KIT_TO_CART.type,
  payload: api_AddToBuckCart('sgrna_kit', config)
});

export const GET_RNA_ORDER_STACK_BY_ID = actionComposer(
  'GET_RNA_ORDER_STACK_BY_ID'
);
export const getRNAOrderStackById = id => ({
  type: GET_RNA_ORDER_STACK_BY_ID.type,
  payload: queryContentStackById('copy_of_rna_order', id)
});

export const SET_DISPLAY_LABEL_ERROR = 'SET_DISPLAY_LABEL_ERROR';
export const setDisplayLabelError = bool => ({
  type: SET_DISPLAY_LABEL_ERROR,
  value: bool
});

export const SET_IS_VALID_LABEL = 'SET_VALID_LABEL';
export const setIsValidLabel = bool => ({
  type: SET_IS_VALID_LABEL,
  value: bool
});

export const SET_MODIFICATION = 'SET_MODIFICATION';
export const setModification = selected => ({
  type: SET_MODIFICATION,
  value: selected
});

export const SET_YIELD = 'SET_YIELD';
export const setYield = selected => ({
  type: SET_YIELD,
  value: selected
});

export const UPDATE_CUSTOMER_LABEL = 'UPDATE_CUSTOMER_LABEL';
export const updateCustomerLabel = text => ({
  type: UPDATE_CUSTOMER_LABEL,
  text: text
});

export const UPDATE_CUSTOMER_SEQUENCE = 'UPDATE_CUSTOMER_SEQUENCE';
export const updateCustomerSequence = (text, bool) => ({
  type: UPDATE_CUSTOMER_SEQUENCE,
  text: text,
  value: bool
});

export const UPDATE_SGRNA_KIT_IN_CART = actionComposer(
  'UPDATE_SGRNA_KIT_IN_CART'
);
export const updateSgRnaKitInCart = config => ({
  type: UPDATE_SGRNA_KIT_IN_CART.type,
  payload: api_AddToBuckCart('sgrna_kit', config)
});
