import _ from 'lodash';

const VALID_RNA_CHARS = ['a', 'c', 'g', 'u'];

export const getSequenceErrorPositions = sequence => {
  let error_pos = [];
  for (let i = 0; i < sequence.length; i++) {
    if (!isRNABase(sequence[i])) {
      error_pos.push(i + 1);
    }
  }
  return error_pos;
};

const isRNABase = char => {
  return VALID_RNA_CHARS.includes(char);
};

export const isValidLabel = value => value > 0 && value < 21;

export const replaceTs = sequence => {
  return sequence.split('t').join('u');
};

export function sequenceErrorMsg(errorPositions, minLength, maxLength) {
  let lengthsStr = `${minLength.toString()}-${maxLength.toString()}`;

  if (errorPositions.length) {
    return `Invalid RNA base, position: ${_.join(errorPositions)}.`;
  } else {
    return `Invalid sequence length. Valid length between ${lengthsStr} bases`;
  }
}
