import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_getOrderDetails = orderToken =>
  fRequest(`${config.buckRootUrl}/api/order/summary/${orderToken}/`, {
    method: 'GET'
  });

export const api_getOrderShippingEstimates = orderToken =>
  fRequest(
    `${config.buckRootUrl}/api/order/shipping-estimates/${orderToken}/`,
    {
      method: 'GET'
    }
  );

export const api_updateOrderAddress = (
  orderToken,
  addressType,
  addressId,
  address
) =>
  fRequest(`${config.buckRootUrl}/api/order/update-address/${orderToken}/`, {
    method: 'POST',
    data: { address_type: addressType, address_id: addressId, address: address }
  });

export const api_validateAddress = address =>
  fRequest(`${config.buckRootUrl}/api/validate-address/`, {
    method: 'POST',
    data: address
  });

export const api_updateOrderShippingMethod = (
  orderToken,
  shippingMethod,
  fedexAccountNumber
) =>
  fRequest(
    `${config.buckRootUrl}/api/order/${orderToken}/update-shipping-method/`,
    {
      method: 'POST',
      data: {
        shipping_method: shippingMethod,
        fedex_account_number: fedexAccountNumber
      }
    }
  );
