import _ from 'lodash';

// Prices for product look like:
// {
//     "product_slug": "crisprevolution-sgrna-ez-kit",
//     "product_type": "oligo",
//     "yield_options": [
//                         {id:'3nmol', display:'3 nmol'},
//                     ],
//     "modified_options": [
//                             {id: "none", display: "Not stabilized"},
//                             {id: "stabilized", display: "3-O_=)"}
//                         ],
//     "min_lenth": 10,
//     "max_length": 21,
//     "list_price": 299.00,
//     "price": 299.00
// }

// old angular app uses product configuration to match price.
// looks like this:
// {
//     length: int,
//     modification: string,
//     selected_yield: string,
//     product_slug: string,
//     product_type: string
// }
//

// return list of price objects matching the config.
export function filterPrices(buckPricingInfo, prodConfig = {}) {
  const {
    product_slug,
    product_type,
    selected_yield,
    selected_modification,
    length,
    price_type
  } = prodConfig;

  const flatPricingInfo = _.flatten(Object.values(buckPricingInfo));

  let prices = _.filter(flatPricingInfo, option => {
    if (!_.isNil(product_slug)) {
      if (option['product_slug'] !== product_slug) {
        return false;
      }
    }
    if (!_.isNil(product_type)) {
      if (option['product_type'] !== product_type) {
        return false;
      }
      if (product_type === 'standalone') {
        return true;
      }
    }
    if (!_.isNil(price_type)) {
      if (option['price_type'] !== price_type) {
        return false;
      }
    }
    //find any matching yields
    if (!_.isNil(selected_yield)) {
      // make list of yield ids
      let optionYields = _.map(option.yield_options, 'id');
      // if no match to prodConfig, filter this `option` out
      if (!_.includes(optionYields, selected_yield.id)) {
        return false;
      }
    }
    //find any matching modifications
    if (!_.isNil(selected_modification)) {
      // make list of mod ids
      let optionModifications = _.map(option.modified_options, 'id');
      // if no match to prodConfig, filter this `option` out
      if (!_.includes(optionModifications, selected_modification.id)) {
        return false;
      }
    }
    // check if length is in the option's range
    if (!_.isNil(length)) {
      if (!_.inRange(length, option.min_length, option.max_length + 1)) {
        return false;
      }
    }
    // if you make it here, you have a match
    return true;
  });
  return prices;
}

// digest list of price objects into yield and modification options. Price objects should be of single product_slug
// these are used to populate dropdowns.
export function getYieldAndModOptions(productTypeOptions) {
  const cleanOptions = {
    yieldOptions: [],
    modificationOptions: [],
    minLength: null,
    maxLength: null
  };
  // extract all option objects into flat list, removing duplicates
  for (const i of productTypeOptions) {
    let yieldOptions = i.yield_options;
    let modOptions = i.modified_options;
    let minLength = i.min_length;
    let maxLength = i.max_length;

    for (const j of yieldOptions) {
      if (!cleanOptions.yieldOptions.some(x => x.id === j.id)) {
        cleanOptions.yieldOptions.push(j);
      }
    }

    for (const k of modOptions) {
      if (!cleanOptions.modificationOptions.some(x => x.id === k.id)) {
        cleanOptions.modificationOptions.push(k);
      }
    }

    if (!cleanOptions.minLength || cleanOptions.minLength < minLength) {
      cleanOptions.minLength = minLength;
    }

    if (!cleanOptions.maxLength || cleanOptions.maxLength > maxLength) {
      cleanOptions.maxLength = maxLength;
    }
  }

  // sort yieldOptions - _.words splits the display value on space. We turn the
  // leading string into an int for sorting
  cleanOptions.yieldOptions = _.sortBy(cleanOptions.yieldOptions, [
    x => _.toNumber(_.words(x.display)[0])
  ]);

  return cleanOptions;
}

export function getPriceConfigError(
  selectedConfig,
  priceList,
  minLength,
  maxLength
) {
  // Summary: if user has finished configuring the product, but there is no price in buck show an error message.
  const { selected_yield, selected_modification, length } = selectedConfig;
  // do the important bits have values?
  let completeCongfig =
    selected_yield.id &&
    _.inRange(length, minLength, maxLength + 1) &&
    selected_modification.id
      ? true
      : false;

  // is priceList is empty or is there an option without a price?
  let emptyPrice = priceList.length === 0 || !priceList[0].price ? true : false;
  return completeCongfig && emptyPrice;
}

export function lowestPriceBySlug(buckPricingInfo, slug, basePrice) {
  // calculate lowest flat price for item
  let lowestPrice;
  if (basePrice) {
    lowestPrice = basePrice;
  } else {
    let pricesForSlug = filterPrices(buckPricingInfo, {
      product_slug: slug,
      price_type: 'price'
    });
    let sortedPrices = _.sortBy(pricesForSlug, ['price']);
    lowestPrice = sortedPrices.length ? sortedPrices[0].price : 0.0;
  }

  // calculate lowest percent off price for item
  let percentOffsForSlug = filterPrices(buckPricingInfo, {
    product_slug: slug,
    price_type: 'percent'
  });
  let sortedPercentOffs = _.sortBy(percentOffsForSlug, ['price']);
  let highestPercentOff = sortedPercentOffs.length
    ? sortedPercentOffs[sortedPercentOffs.length - 1].price
    : 0.0;
  let lowestPercentOffPrice = highestPercentOff
    ? lowestPrice * (1 - 0.01 * highestPercentOff)
    : lowestPrice;

  // calculate lowest flat off price for item
  let fixedOffsForSlug = filterPrices(buckPricingInfo, {
    product_slug: slug,
    price_type: 'fixed'
  });
  let sortedFixedOffs = _.sortBy(fixedOffsForSlug, ['price']);
  let highestFixedOff = sortedFixedOffs.length
    ? sortedFixedOffs[sortedFixedOffs.length - 1].price
    : 0.0;
  let lowestFixedOffPrice = highestFixedOff
    ? lowestPrice - highestFixedOff
    : lowestPrice;

  return Math.min(lowestPrice, lowestPercentOffPrice, lowestFixedOffPrice);
}
