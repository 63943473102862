import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_getOrderDetails = orderToken =>
  fRequest(`${config.buckRootUrl}/api/order/summary/${orderToken}/`, {
    method: 'GET'
  });

export const api_recalculateOrderQuote = orderToken =>
  fRequest(
    `${config.buckRootUrl}/api/order/recalculate_order_prices/${orderToken}/`,
    {
      method: 'POST'
    }
  );
