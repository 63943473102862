import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_LANDING_STACK = actionComposer('GET_LANDING_STACK');
export const getLandingStack = url => ({
  type: GET_LANDING_STACK.type,
  payload: queryContentStackByUrl('landing', url, {
    includedReferences: [
      'content.quote.quote_reference',
      'content.blog.blog_reference',
      'content.resource.resource_reference',
      'content.scrolling_quotes.quotes',
      'content.table.table_reference'
    ]
  })
});
