import {
  ADD_MULTIPLEX_SGRNA_TO_CART,
  GET_MULTIPLEX_GENES,
  GET_MULTIPLEX_MOUSE_GENES,
  GET_MULTIPLEX_ORDER_STACK_BY_ID,
  GET_SKU_PRICING,
  RESET_REDIRECT_TO_CART,
  SET_MULTIPLEX_GENOME,
  SET_MULTIPLEX_GENE,
  SET_ADD_ON,
  SET_YIELD
} from './actions';

import { GET_BUCK_PRICING } from 'features/buckaneer/priceOptionsUtils/actions';
import { lowestPriceBySlug } from 'features/buckaneer/priceOptionsUtils/optionsUtils';

export const getInitialState = () => ({
  standardAddOnOptions: [
    { order: 0, id: 'none', display: 'None' },
    {
      order: 15,
      id: 'cas9_2nls_nuclease_300pmol',
      display: 'SpCas9 2NLS Nuclease (300 pmol)'
    },
    {
      order: 16,
      id: 'cas9_2nls_nuclease_1000pmol',
      display: 'SpCas9 2NLS Nuclease (1000 pmol)'
    }
  ],
  humanAddOnOptions: [
    {
      order: 20,
      id: 'tok_multiguide',
      display: 'Transfection Optimization Kit (Multi-guide), with Cas9'
    }
  ],
  mouseAddOnOptions: [
    {
      order: 20,
      id: 'control_pos_sgrna_mod_mouse_rosa26',
      display: 'Positive Control, Mouse Rosa26, mod-sgRNA, 1nmol, Tube'
    }
  ],
  buck_prices: {},
  genomes: [],
  genes: [],
  mouse_genes: [],
  redirect_to_cart: false,
  selected_genome: '',
  selected_gene: '',
  selected_add_on: '',
  selected_add_on_user_price: '',
  selected_yield: { quantity: '', quantity_unit: '' },
  sku_pricing: {},
  stack: {
    add_to_cart_button_text: '',

    gene_help_text: '',
    gene_input_label: '',
    gene_input_placeholder: '',

    genome_help_text: '',
    genome_input_label: '',
    genome_input_placeholder: '',

    get_help_text: '',

    yield_help_text: '',
    yield_input_label: ''
  },
  users_add_on_prices_by_slug: ''
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case ADD_MULTIPLEX_SGRNA_TO_CART.FULFILLED:
      return {
        ...state,
        loading: false,
        redirect_to_cart: true
      };
    case ADD_MULTIPLEX_SGRNA_TO_CART.PENDING:
      return {
        ...state,
        loading: true
      };
    case ADD_MULTIPLEX_SGRNA_TO_CART.REJECTED:
      return {
        ...state,
        loading: false
        // set some state to display error to user.
      };
    case GET_BUCK_PRICING.FULFILLED:
      const buckPrices = action.payload;
      const users_addon_prices = (options, out = {}) => {
        options.forEach(option => {
          out[option.id] = lowestPriceBySlug(buckPrices, option.id);
        });
        return out;
      };
      return {
        ...state,
        buck_prices: buckPrices,
        users_add_on_prices_by_slug: users_addon_prices([
          ...state.standardAddOnOptions,
          ...state.humanAddOnOptions,
          ...state.mouseAddOnOptions
        ])
      };
    case GET_BUCK_PRICING.PENDING:
      return {
        ...state
      };
    case GET_BUCK_PRICING.REJECTED:
      return {
        ...state
      };
    case GET_MULTIPLEX_GENES.FULFILLED:
      return {
        ...state,
        loading: false,
        genes: action.payload
      };
    case GET_MULTIPLEX_GENES.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_MULTIPLEX_GENES.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_MULTIPLEX_MOUSE_GENES.FULFILLED:
      return {
        ...state,
        loading: false,
        mouse_genes: action.payload
      };
    case GET_MULTIPLEX_MOUSE_GENES.PENDING:
      return {
        ...state,
        loading: false // Do not wait for mouse genes
      };
    case GET_MULTIPLEX_MOUSE_GENES.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_MULTIPLEX_ORDER_STACK_BY_ID.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_MULTIPLEX_ORDER_STACK_BY_ID.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_MULTIPLEX_ORDER_STACK_BY_ID.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_SKU_PRICING.FULFILLED:
      return {
        ...state,
        loading: false,
        sku_pricing: action.payload
      };
    case GET_SKU_PRICING.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_SKU_PRICING.REJECTED:
      return {
        ...state,
        loading: false
      };
    case RESET_REDIRECT_TO_CART:
      return {
        ...state,
        redirect_to_cart: false
      };
    case SET_MULTIPLEX_GENOME:
      return {
        ...state,
        selected_genome: action.genome
      };
    case SET_MULTIPLEX_GENE:
      return {
        ...state,
        selected_gene: action.gene
      };
    case SET_ADD_ON:
      return {
        ...state,
        selected_add_on: action.value.value
      };
    case SET_YIELD:
      return {
        ...state,
        selected_yield: action.value
      };
    default:
      return state;
  }
};
