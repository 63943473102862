import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_CASE_STUDY_STACK = actionComposer('GET_CASE_STUDY_STACK');
export const getCaseStudyDetailStack = url => ({
  type: GET_CASE_STUDY_STACK.type,
  payload: queryContentStackByUrl('case_study_detail', url, {
    includedReferences: [
      'footer_cta',
      'content.sections.cta.cta_reference',
      'content.sections.resource.resource_reference',
      'content.sections.blog.blog_reference',
      'content.sections.podcast.podcast_reference',
      'content.sections.section.content.table.table_reference',
      'content.sections.section.content.figure.figure_reference',
      'content.sections.section.content.quote.quote_reference',
      'content.sections.section.content.publication.publication_reference',
      'content.sections.section.content.publication.publication_reference.categories_tags.product',
      'content.sections.section.content.publication.publication_reference.categories_tags.application',
      'content.sections.section.content.publication.publication_reference.categories_tags.research_area',
      'categories_tags.application',
      'categories_tags.research_area',
      'categories_tags.editing_method',
      'categories_tags.paper_type',
      'categories_tags.product',
      'categories_tags.product.family',
      'categories_tags.model_organism',
      'categories_tags.customer_affiliation'
    ]
  })
});
