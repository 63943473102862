import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_CASE_STUDIES_LANDING_STACK = actionComposer(
  'GET_CASE_STUDIES_LANDING_STACK'
);
export const getCaseStudiesLandingStack = () => ({
  type: GET_CASE_STUDIES_LANDING_STACK.type,
  payload: queryContentStackByUrl('case_studies', '/case-studies', {
    includedReferences: [
      'featured_case_studies',
      'featured_case_studies.customer',
      'featured_case_studies.categories_tags.application',
      'featured_case_studies.categories_tags.research_area',
      'featured_case_studies.categories_tags.editing_method',
      'featured_case_studies.categories_tags.paper_type',
      'featured_case_studies.categories_tags.product',
      'featured_case_studies.categories_tags.product.family',
      'featured_case_studies.categories_tags.model_organism',
      'featured_case_studies.categories_tags.customer_affiliation',
      'footer_cta'
    ]
  })
});
