import * as serviceWorker from 'registerServiceWorker';
import _ from 'lodash';

// React
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Initial view
import Layout from 'views/Layout';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

// Middleware
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import errorMiddleware from './middleware/error';
import { createLogger } from 'redux-logger';

// Make React-Redux store
import rootReducer from 'init/reducers';

//Set JSS injection point before styled-components (so SC styles get priority/are added last)
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

//Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from 'init/config';
import '@contentstack/live-preview-utils/dist/main.css';

import { loadState, saveState } from 'utilities/localStorage';

const logMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production'
});

const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = 'jss-insertion-point';

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(
    thunkMiddleware,
    errorMiddleware,
    promiseMiddleware,
    logMiddleware
  )
);

// Some states we want to persist to localstorage
store.subscribe(
  _.throttle(() => {
    saveState({
      orderODL: store.getState().orderODL
    });
  }, 1000)
);

// Render the view

const stripePromise = loadStripe(config.stripeConfig.api_key);

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://use.typekit.net/rrn5czh.css'
          }
        ]
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </Provider>
    </Elements>
  </JssProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
